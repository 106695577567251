import { Fragment, useState, useRef, useEffect, FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import PgeButton from '../../pge-button/_PgeButton';
import RichText from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { CtaCardProps, Omits } from './types.d';
import { ColorName } from '../../../constants';

interface ExpandProps extends CtaCardProps {
  readonly imageHeight?: number;
  readonly imageRatio?: number;
}

const CtaCard: FC<CtaCardProps> = ({
  heading,
  button,
  buttonLink,
  image,
  mobileImage,
  shortDescription,
  imageBorderColor = 'DarkBlue',
  ...props
}) => {
  // Vars
  const mobileFile = mobileImage?.file;
  const imageHeight = mobileFile?.details?.image?.height ?? 1200;
  const imageWidth = mobileFile?.details?.image?.width ?? 360;
  const imageRatio = Math.floor((imageHeight / imageWidth) * 100);

  const { className = '', ...attrs } = useAttrs<CtaCardProps>(props, Omits);
  const $img = useRef<HTMLDivElement>(null);
  const $btn = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme()

  useEffect(() => {
    if (expanded) {
      $img.current?.classList.add('isExpanded');
      $btn.current?.setAttribute('disabled', '');
    }
  }, [expanded]);

  return (
    <aside
      css={{
        display: 'flex',
        boxSizing: 'border-box',
        contain: 'content',
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        borderRadius: '5px',

        backgroundColor: imageBorderColor === 'DarkBlue' ? ColorName['DarkBlue']
          : imageBorderColor === 'LightBlue' ? ColorName['LightBlue']
          : ColorName['DarkGrey'], // default to grey (neutral color)

        '& .RichText': {
          width: '100%',
          padding: theme.spacing(2, 4, 4),
          order: 3,
          color: theme.palette.background.paper,

          '& a, & button': {
            color: 'inherit',
            textDecoration: 'underline',
          },

          '& hr': {
            backgroundColor: theme.palette.background.paper,
          },
        },
      }}
      aria-label={heading || undefined}
      {...attrs}
    >
      {heading && (
        <Typography variant="h1" css={{
          width: '100%',
          padding: theme.spacing(0, 4),
          color: theme.palette.background.paper,

          [theme.breakpoints.up('sm')]: {
            flex: 1,
          },
        }}>
          {heading}
        </Typography>
      )}
      {image?.file?.url && (
        <div
          css={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            paddingTop: 400,
            position: 'relative',
            marginTop: theme.spacing(2.5),
            order: 2,
            overflow: 'hidden',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            fontSize: 0,
            transform: 'translateZ(0)',
            transition: 'padding .5s',

            [theme.breakpoints.up('sm')]: {
              marginTop: theme.spacing(4),
              paddingTop: 0,
            },

            '&::after': {
              display: 'block',
              width: '100%',
              height: '40%',
              position: 'absolute',
              bottom: 0,
              content: '""',
              zIndex: 1,
              background: 'linear-gradient(transparent, #fff) no-repeat',

              [theme.breakpoints.up('sm')]: {
                content: 'none',
              },
            },

            '& picture': {
              display: 'block',
              width: '100%',
            },

            '& img': {
              display: 'block',
              width: '100%',
              height: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,

              [theme.breakpoints.up('sm')]: {
                position: 'relative',
              },
            },

            '&.isExpanded': {
              paddingTop: `${imageRatio}%`,

              '&::after': {
                opacity: 0,
                transition: '.2s',
              },

              [theme.breakpoints.up('sm')]: {
                paddingTop: 0,
              },
            },
          }}
          ref={$img}
        >
          <picture>
            {mobileFile && (
              <Fragment>
                <source media="(max-width:767px)" srcSet={mobileFile?.url || undefined} />
                <source media="(min-width:768px)" srcSet={image.file.url} />
              </Fragment>
            )}
            <img src={image.file.url} alt="" height="450" width="1200" />
          </picture>
          <PgeButton
            css={{
              position: 'absolute',
              bottom: theme.spacing(2),
              zIndex: 2,

              '.isExpanded &': {
                opacity: 0,
                transition: 'opacity .2s',
              },

              [theme.breakpoints.up('sm')]: {
                'button&': { display: 'none' },
              },
            }}
            theme="Pill"
            startIcon="Plus"
            onClick={() => setExpanded(true)}
            ref={$btn}
          >
            Show more
          </PgeButton>
        </div>
      )}
      <RichText className={'RichText bkg-Dark'}>
        {shortDescription}
      </RichText>
      <PgeButton
        css={{
          margin: theme.spacing(2, 'auto', 0),
          order: 1,

          [theme.breakpoints.up('sm')]: {
            alignSelf: 'start',
            margin: theme.spacing(0, 3, 0, 'auto'),
          },
        }}
        link={buttonLink}
        theme="Outline"
      >
        {button?.buttonText}
      </PgeButton>
    </aside>
  );
}

export default CtaCard;
